import React from 'react'
function Carousel({images}) { 
  return (
    <>
    <div className="container carousel_model" height="60dvh" width="80%">
            <button className="carousel_btn btn_left"></button>
                <div className="image">
                    {images.map((image,index) => {
                        if(index===0) return (<img className="active" key={index} src={image} alt={"img_"+index}/>)
                        else return (<img key={index} src={image} alt={"img_"+index}/>)
                    })}
                </div>
            <button className="carousel_btn btn_right"></button>
            <div className='quick_switch'></div>
            <div className='progress_bar'></div>
            {/*<h2 className='carousel_text'>Zum Beispiel letzte Bau oder Neuigkeiten</h2>*/} 
        </div>
    </>
  )
}

export default Carousel