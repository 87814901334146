import React, { useEffect } from 'react'
import '../css/carousel.css'
import '../css/Home.css'
import carousel_img1 from '../images/carousel/1.jpg'
import carousel_img2 from '../images/carousel/2.jpg'
import carousel_img3 from '../images/carousel/3.jpg'
import carousel_img4 from '../images/carousel/4.jpg'
import carousel_img5 from '../images/carousel/5.jpg'
import carousel_img6 from '../images/carousel/6.jpg'
import servicesData from '../data/services.json'
import Carousel from '../compontents/Home/Carousel.js'
import Referenzen from '../compontents/Home/Referenzen.js'
import Über_uns from '../compontents/Home/Über_uns.js'
import Mitarbeiter from '../compontents/Home/Mitarbeiter.js'
import Elektroanlagen from '../compontents/Home/Elektroanlagen.js'
import Cards from '../compontents/Home/Cards.js'
import carousel_initialization from '../compontents/carousel.js'

function Home() {
    useEffect(()=>{
      window.onscroll = ()=>{
        const scroll = document.querySelector('.scroll_to_top')
        const first_section = document.querySelector('.first_section')
        const über_uns = document.querySelector('.über_uns')
        const mitarbeiter = document.querySelector('.mitarbeiter')
        const elektroanlagen = document.querySelector('.elektroanlagen')
        if(window.scrollY>1000){
          scroll.classList.add('scroll')
        }
        else{
          scroll.classList.remove('scroll')
        }
        if(window.scrollY<50){
          document.querySelector('.nav').classList.remove("sticky")
        }
        if(window.scrollY>80){
          document.querySelector('.nav').classList.add("sticky")
        }
        if (window.scrollY > 0 && window.scrollY > window.innerHeight + 230) {
          first_section.classList.remove('visible');
        } else {
          first_section.classList.add('visible');
        }
        if(window.scrollY>80 && über_uns){
          über_uns.classList.add("slide_up")
        }
        if(window.scrollY>1300 && mitarbeiter){
          mitarbeiter.classList.add("slide_up")
        }
        if(window.scrollY>1600 && elektroanlagen){
          elektroanlagen.classList.add("slide_up")
        }
        if(window.scrollY>1900){
          document.querySelectorAll('.cards div').forEach(x=>x.classList.add("slide_up"))
        }
      }
      carousel_initialization()
    },[])
    const images = [carousel_img1,carousel_img2,carousel_img3,carousel_img4,carousel_img5,carousel_img6]
  return (
    <>
    <div className='mt-3'>
        <div className='first_section visible'>
            <Carousel images={images}/>
            <Referenzen/>
        </div>
        <div className='second_section'>
          <Über_uns/>
        </div>
        <div className='third_section'>
        <Mitarbeiter/>
        <Elektroanlagen servicesData={servicesData}/>
        <Cards/>
        </div>
    </div>
    </>
    
  )
}

export default Home