import React, { useEffect } from 'react'
import '../css/Referenzen.css'
import referencen from '../data/referenzen.json'
import image_1 from '../images/Referenzen/Neubau ALDI-Markt mit 105WE.jpg'
import image_2 from '../images/Referenzen/Neubau ALDI-Markt mit 34WE_1.jpg'
import image_3 from '../images/Referenzen/Neubau ALDI-Markt mit 34WE_2.jpg'
import image_4 from '../images/Referenzen/Umbau Hotel-und Büro-Komplex_1.jpg'
import image_5 from '../images/Referenzen/Umbau Hotel-und Büro-Komplex_2.jpg'
import image_6 from '../images/Referenzen/Neubau Sozial- Gebäude_1.jpg'
import image_7 from '../images/Referenzen/Neubau Sozial- Gebäude_2.jpg'
import image_8 from '../images/Referenzen/Neubau Wohnhaus_1.jpg'
import image_9 from '../images/Referenzen/Neubau Wohnhaus_2.jpg'
import image_10 from '../images/Referenzen/Referenzen_EIKOM-089.jpg'
import image_11 from '../images/Referenzen/Referenzen_EIKOM-090.jpg'
import image_12 from '../images/Referenzen/Referenzen_EIKOM-091.jpg'
import image_13 from '../images/Referenzen/Referenzen_EIKOM-092.jpg'
import image_14 from '../images/Referenzen/Wachtel Immobilien - 1.jpg'
import aldi from '../images/Referenzen/aldi.png'
import bremer from '../images/Referenzen/bremer.png'
import strabag from '../images/Referenzen/strabag.png'
import schulte from '../images/Referenzen/schulte.png'
import bahlsen from '../images/Referenzen/bahlsen.png'
import kati from '../images/Referenzen/kati.png'
import wachtel from '../images/Referenzen/wachtel.png'
function Referenzen() {
  const logos = [
    { name: 'Aldi', src: aldi },
    { name: 'Bremer', src: bremer },
    { name: 'Strabag', src: strabag },
    { name: 'Schulte', src: schulte },
    { name: 'Bahlsen', src: bahlsen },
    { name: 'Kati', src: kati },
    { name: 'Wachtel', src: wachtel }
  ]
  let start_interval
  let references
  function startInterval(){
    start_interval = setInterval(() => {
      references.forEach(r=> {
        const carousel_images = r.parentElement.querySelectorAll('.other_images .r_image')
        const images = r.querySelectorAll('.r_image')
        const on_screen = (r.getBoundingClientRect().height + r.getBoundingClientRect().y) > 0
        const start_animate = (r.getBoundingClientRect().height + r.getBoundingClientRect().y) < (window.innerHeight + window.innerHeight / 2)
        if (start_animate && on_screen) {
          next_image(images,carousel_images)
        }
    })
    }, 5000)
  }
  const handle_next_image = () => {
      startInterval()
      const handleScroll = () => {
          references.forEach(r => {
            const on_screen = (r.getBoundingClientRect().height + r.getBoundingClientRect().y) > 0
            const start_animate = (r.getBoundingClientRect().height + r.getBoundingClientRect().y) < (window.innerHeight + window.innerHeight / 2)
            const progress_bar = r.querySelector('.image_progress')
            if(progress_bar){
              progress_bar.classList.remove('start')
              if (start_animate && on_screen) {
                setTimeout(()=>{progress_bar.classList.add('start')})
              } else {
                clearInterval(start_interval)
                startInterval()
                progress_bar.classList.remove('start')
              }
            }
          })
      }
      handleScroll()
      window.addEventListener('scroll', handleScroll)
  }
  const next_image = (images,carousel_images)=>{
    let current_image 
    images.forEach((img, i) => {
      if (img.classList.contains('active')) {
        current_image = i
      }
      img.classList.remove('active')
      carousel_images[i].classList.remove('active')
    })
    if (images[current_image + 1]) {
      carousel_images[current_image + 1].classList.add('active')
      images[current_image + 1].classList.add('active')
    } else {
      carousel_images[0].classList.add('active')
      images[0].classList.add('active')
    }
  }
  const schulte_images = [image_1,image_2,image_3]
  const bremer_images = [image_4,image_5]
  const bahlsen_images = [image_6,image_7]
  const phoenix_images = [image_8,image_9]
  const aldi_images = [image_10,image_11,image_12,image_13]
  const wachtel_images = [image_14]
  function handleCarousel (event){
    clearInterval(start_interval)
    startInterval()
    const selection = event.target.closest('.other_images')
    if(selection){
      const carousel_images = selection.querySelectorAll('.r_image')
      const all_images = selection.parentElement.querySelectorAll('.reference_section .r_image')
      const progress_bar = selection.parentElement.querySelector('.image_progress')
      progress_bar.classList.remove('start')
      setTimeout(()=>{progress_bar.classList.add('start')})
      
      const selected_image = event.target.closest('.other_images .r_image')
      carousel_images.forEach((img, i) => {
        all_images[i].classList.remove('active')
        carousel_images[i].classList.remove('active')
        if (img===selected_image) {
          all_images[i].classList.add('active')
          carousel_images[i].classList.add('active')
        }
      })
    }
  }
  const handleReferences = (name)=>{
    switch (name) {
      case 'Schulte':
        return(
          <>
            {schulte_images.map((image,index)=>(
            <img key={index} className={index===0?('r_image active'):('r_image')} onClick={handleCarousel} src={image} alt={'aldi&schulte_'+index}/>))
            }
            <div className='image_progress'></div>
          </>
        )
      case 'Kati':
        return(
          <>
            {bremer_images.map((image,index)=>(
            <img key={index} className={index===0?('r_image active'):('r_image')} onClick={handleCarousel} src={image} alt={'kati_'+index}/>))
            }
            <div className='image_progress'></div>
          </>
        )
      case 'Bahlsen':
          return(
            <>
              {bahlsen_images.map((image,index)=>(
              <img key={index} className={index===0?('r_image active'):('r_image')} onClick={handleCarousel} src={image} alt={'bahlsen_'+index}/>))
              }
              <div className='image_progress'></div>
            </>
          )
      case 'Phoenix':
          return(
            <>
              {phoenix_images.map((image,index)=>(
              <img key={index} className={index===0?('r_image active'):('r_image')} onClick={handleCarousel} src={image} alt={'phoenix_'+index}/>))
              }
              <div className='image_progress'></div>
            </>
          )
      case 'Aldi':
        return(
          <>
            {aldi_images.map((image,index)=>(
            <img key={index} className={index===0?('r_image active'):('r_image')} onClick={handleCarousel} src={image} alt={'aldi_'+index}/>))
            }
            <div className='image_progress'></div>
          </>
        )
      case 'Wachtel':
        return(
          <>
            {wachtel_images.map((image,index)=>(
            <img key={index} className={index===0?('r_image active'):('r_image')} onClick={handleCarousel} src={image} alt={'wachtel_'+index}/>))
            }
          </>
        )
      default:
        return null
    }
  }
  useEffect(()=>{
    const scroll = document.querySelector('.scroll_to_top')
    window.onscroll = ()=>{
      if(window.scrollY>1000){
        scroll.classList.add('scroll')
      }
      else{
        scroll.classList.remove('scroll')
      }
      if(window.scrollY<50){
        document.querySelector('.nav').classList.remove("sticky")
      }
      if(window.scrollY>80){
        document.querySelector('.nav').classList.add("sticky")
      }
    }
    const scroll_to = window.location.href.split('#')[1]
    if(scroll_to){
      const element = document.getElementById(scroll_to)
      if (element) {
          element.scrollIntoView({ behavior: 'smooth' })
      }
    }
    references = document.querySelectorAll('.reference_section:not(.no_image)')
    handle_next_image()
    return () => {
      clearInterval(start_interval)
    }
  },[])
  return (
    <>
      <div className='references container my-3 d-flex flex-column gap-5'>
          {referencen
            .filter(r => r.images !== 0)
            .map((r, key) => (
              <div className='position-relative' key={key} id={r.name.toLocaleLowerCase()}>
                  <div className='reference_section'>
                      {handleReferences(r.name)}
                      <div className='reference_text d-flex flex-column align-items-center'>
                        {logos.find(logo => logo.name === r.name) ? (
                          <img className={'r_logo mb-3 rounded-3 ' + r.name.toLocaleLowerCase()} src={logos.find(logo => logo.name === r.name).src} alt={r.name} />
                        ) : (
                          <h3>{r.name}</h3>
                        )}
                          {/*<p className='standort'>{r.locations.map((location, index) => (<span key={index}>{location}</span>))}</p>*/}
                      </div>
                  </div>
                  <div className='other_images'>
                      {handleReferences(r.name)}
                  </div>
              </div>
          ))}
      </div>
      <div className='no_image_references container my-3'>
          {referencen
            .filter(r => r.images === 0)
            .map((r, key) => (
              <div className='referenz' key={key} id={r.name.toLocaleLowerCase()}>
                <div className='d-flex flex-column align-items-center'>
                  {logos.find(logo => logo.name === r.name) ? (
                    <img className={'r_logo mb-3 rounded-3 ' + r.name.toLocaleLowerCase()} src={logos.find(logo => logo.name === r.name).src} alt={r.name} />
                    ) : (
                    <h3>{r.name}</h3>
                  )}
                 {/*<p>Standort : {r.locations.map((location, index) => (<span key={index}>{location}{index !== r.locations.length - 1 ? ', ' : ''}</span>))}</p>*/}
                </div>
              </div>
          ))}
      </div>
    </>
  )
}

export default Referenzen