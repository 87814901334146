import React, { useEffect } from 'react'

function Elektroanlagen({servicesData}) {
    
    let services_interval
    /*function start_services(){
        services_interval = setInterval(()=>{
            const m_services = document.querySelectorAll('.anlage div')
            let nextService
            m_services.forEach((service, index) => {
                if (service.classList.contains('active')) {
                    if (index < m_services.length - 1) {
                        nextService = m_services[index + 1]
                    } else {
                        nextService = m_services[0]
                    }
                }
                service.classList.remove('active')
            })
            const services = document.querySelectorAll('.anlagen h5')
            const select = nextService.getAttribute('data-anlage')
            const selected = document.querySelector(`.anlagen h5[data-anlage='${select}']`)
            services.forEach(s=>{
                s.classList.remove('active')
            })
            selected.classList.add('active')
            nextService.classList.add('active')
        },5000)
    }*/
    function handle_services (event) {
        clearInterval(services_interval)
        const m_select = event.target.getAttribute('data-anlage')
        const m_selected_service = document.querySelector(`.anlage div[data-anlage='${m_select}']`)

        const m_services = document.querySelectorAll(`.anlage div`)
        m_services.forEach(s=>{
            s.classList.remove('active')
        })
        m_selected_service.classList.add('active')
        const selected = event.target
        const services = document.querySelectorAll('.anlagen h5')
        services.forEach(s=>{
            s.classList.remove('active')
        })
        selected.classList.add('active')
        //start_services()
    }
    useEffect(()=>{
        //start_services()
        return () => {
            clearInterval(services_interval)
        }
    },[
        //start_services,
        services_interval])
  return (
    <div className='container elektroanlagen'>
        <h3 className='text-center pt-3'>Elektroanlagen</h3>
        <div className='d-flex mt-5 justify-content-between'>
            <div className='anlagen d-flex flex-column justify-content-between'>
                {servicesData.services.map((service,index) => (
                    <h5 className={index===0?('active'):('')} key={service.id} data-anlage={service.id} onClick={handle_services}>{service.name}</h5>
                ))}
            </div>
            <div className='anlage'>
                {servicesData.services.map((service,index) => (
                    <div className={index===0?('active'):('')} key={service.id} data-anlage={service.id}>
                        {service.subcategories.map((sub,key)=>(
                            <h6 className='text-center' key={key}>{sub}</h6>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    </div>
  )
}

export default Elektroanlagen