import React from 'react'

function Mitarbeiter() {
  return (
    <div className='container mitarbeiter mt-5'>
        <h1 className='text-center'><i className="fa-solid fa-users m_icon"></i></h1>
        <h3 className='text-center pt-3'>Mitarbeiter</h3>
        <p>Die Firma beschäftigt z.Z. neben den Geschäftsführer und Gesellschafter noch 2 weitere Gesellschafter. Neben 15 Elektriker sind noch 4 Bürokräfte und 2 Elektroingenieur bei der EIKOM GmbH angestellt. Unser Büroteam sorgt dafür, dass unsere Kunden, nach der Aufmaß, rechtzeitig die Angebote und alle Alternativen schriftlich und erklärbar bekommen. Wir bieten auch die Beratung mit den Fachingenieur, Bauleiterin und Meister.</p>
    </div>
  )
}

export default Mitarbeiter