import React, { useEffect } from 'react'
import logo from '../images/Logo.jpg'
import '../css/Nav.css'
function Nav() {
  const pathname = window.location.pathname.slice(1)
  const links = ['referenzen','kontakt']
  function capitaliseFirstLetter (word) {
    if (!word) return '';
    return word.charAt(0).toUpperCase() + word.slice(1);
  }
  useEffect(()=>{
    document.querySelector('.scroll_to_top').onclick = ()=>{
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  },[])
  return (
    <>
        <div className='nav container justify-content-between align-items-center'>
            <div className='d-flex gap-4'>
                <a className='link' href='../'><img className='logo' src={logo} alt='logo'/></a>
            </div>
            <div className='d-flex gap-4'>
                <label className='menu' htmlFor='menu'><span></span><span></span><span></span></label>
                <input className='d-none' type='checkbox' name='menu' id='menu'/>
                <div className='links d-flex gap-4 align-items-center'>
                  {pathname!==''?(<a className='link' href='../'>{capitaliseFirstLetter('Homepage')}</a>):('')}
                  {links.map((link,key)=>(
                    <div key={key}>
                    {pathname.includes(link)?(<a className='link active'>{capitaliseFirstLetter(link)}</a>):(<a  href={'../'+link} className='link'>{capitaliseFirstLetter(link)}</a>)}
                    </div>
                  ))}
                </div>
            </div>
        </div>
        <div className='scroll_to_top'><i className="fa-solid fa-angle-up fa-xl"></i></div>
    </>
  )
}

export default Nav