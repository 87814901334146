import React from 'react'

function Über_uns() {
  return (
    <div className='über_uns'>
        <div className='container text'>
            <h1 className='text-center'>Über uns</h1>
            <h3 className='mt-5'>Die EIKOM GbR wurde im Jahre 1992 in Lübbenau gegründet.</h3>
            <h3>Gegenstand des Unternehmens sind die Durchführung elektronischer Installationen aller Art, Installation von Fernmeldeanlagen, Datennetz und Einbruchanlagen. Im September 2019 wurde die EIKOM GbR in die Geschäftsform der EIKOM GmbH umgewandelt.</h3>
            <h3>Seit dieser Zeit ist der Umsatz (Auftragsvolumen) stetig gestiegen.</h3>
        </div>
    </div>
  )
}

export default Über_uns