import React from 'react'
import aldi from '../images/Referenzen/aldi.png'
import bremer from '../images/Referenzen/bremer.png'
import strabag from '../images/Referenzen/strabag.png'
import schulte from '../images/Referenzen/schulte.png'
import bahlsen from '../images/Referenzen/bahlsen.png'
import kati from '../images/Referenzen/kati.png'
import wachtel from '../images/Referenzen/wachtel.png'
import '../css/Referenzen.css'

function R_logos() {
  function handleLogoLink (event){
    window.location.href ='./referenzen/#'+event.target.className.split(' ')[1]
  }
  return (
    <div className='logos_slide c_pointer'>
        <img className='r_logo aldi' src={aldi} alt='aldi' onClick={handleLogoLink}></img>
        <img className='r_logo bremmer' src={bremer} alt='bremer' onClick={handleLogoLink}></img>
        <img className='r_logo strabag' src={strabag} alt='strabag' onClick={handleLogoLink}></img>
        <img className='r_logo schulte' src={schulte} alt='schulte' onClick={handleLogoLink}></img>
        <img className='r_logo bahlsen' src={bahlsen} alt='bahlsen' onClick={handleLogoLink}></img>
        <img className='r_logo kati' src={kati} alt='kati' onClick={handleLogoLink}></img>
        <img className='r_logo wachtel' src={wachtel} alt='wachtel' onClick={handleLogoLink}></img>
    </div>
  )
}

export default R_logos