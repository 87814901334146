import React from 'react'
import R_logos from '../r_logos'

function Referenzen() {
  return (
    <div className='referencen my-5'>
        <div className='logos'>
            <R_logos/>
            <R_logos/>
        </div>
    </div>
  )
}

export default Referenzen