import { BrowserRouter, Route, Routes , Navigate } from "react-router-dom"
import Home from "./pages/Home"
import Nav from "./compontents/Nav"
import Footer from "./compontents/Footer"
import Referenzen from "./pages/Referenzen"
import Kontakt from "./pages/Kontakt"
function App() {
  
  return (
    <BrowserRouter>
      <Nav/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/referenzen' element={<Referenzen/>}/>
        <Route path='/kontakt' element={<Kontakt/>}/>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer/>
    </BrowserRouter>
  )
}
export default App
