import React from 'react'
import '../css/Kontakt.css'

function Kontakt() {
  return (
    <>
    <div className='kontakt container my-5 d-flex flex-column align-items-center'>
        <div className='info'>
            <p><strong>Telefon :</strong> <a href="tel:+493081019973">+49 (030) 81019973</a></p>
            <p><strong>Fax :</strong> <a href="tel:+493081019974">+49 (030) 81019974</a></p>
            <p><strong>Office :</strong> <a href= "mailto: info@eikom-gmbh.de">info@eikom-gmbh.de</a></p>
            <p><strong>Geschäftsführung :</strong> <a href= "mailto: jovic@eikom-gmbh.de">jovic@eikom-gmbh.de</a></p>
            <p><strong>Bauleitung :</strong> <a href= "mailto: jakovic@eikom-gmbh.de">jakovic@eikom-gmbh.de</a></p>
            <p><strong>Öffnungszeiten :</strong> Montag – Freitag / 9.00-17.00</p>
        </div>
        <div className='w-100 my-5'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2434.3595104920164!2d13.5530769594344!3d52.4001602730962!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a847a68e4683dd%3A0x4404c6d6d0a84004!2sGlienicker%20Str.%20520%2C%2012526%20Berlin!5e0!3m2!1sen!2sde!4v1717340030958!5m2!1sen!2sde" width="100%" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>
    </>
  )
}

export default Kontakt