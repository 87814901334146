import React from 'react'

function Cards() {
  return (
    <div className='mt-5 container cards'>
        <div className='text-center'>
            <h1><i className="fa fa-plug m_icon"></i></h1>
            <h5 className='mt-4'>Leistungen</h5>
            <p>
                Eine qualitativ hochwertige Kundenberatung ist für uns selbstverständlich, wie die fachgerechte und sichere Installation und Wartung von Elektroanlagen, Netzwerktechnik und Geräten.
            </p>
        </div>
        <div className='text-center'>
            <h1><i className="fa fa-bolt m_icon"></i></h1>
            <h5 className='mt-4'>Elektroinstallation aller Arten</h5>
            <p>Für Privat,Gewerbe,Industrie</p>
        </div>
        <div className='karriere'>
            <h1 className='text-center'><i className="fa fa-file-text-o m_icon"></i></h1>
            <h5 className='text-center mt-4'>Karriere</h5>
            <p className='p-4 mb-5 mt-3'>Möchtest Du dich verändern, suchst neue Herausforderungen oder einen neuen Job?
            <br/><br/>Du hast eine abgeschlossene Berufsausbildung als Elektriker, Elektroinstallateur, Elektromonteur
                oder eine vergleichbare Qualifikation, dann bewirb Dich bei uns.
            <br/><br/>Wir bieten übertarifliche Entlohnung und Urlaubsregelung, einen unbefristeten Arbeitsvertrag, sowie
                eine langfristige Perspektive zur beruflichen Weiterentwicklung.
            <br/><br/>Interessiert? Dann bewirb Dich bei uns unter <a href= "mailto: info@eikom-gmbh.de">info@eikom-gmbh.de</a></p>
        </div>
    </div>
  )
}

export default Cards