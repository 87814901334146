function carousel_initialization (){
    const carousles = document.querySelectorAll('.carousel_model')
    carousles.forEach(carousel_model=>{
        let show_image=0;
        const btn_left = carousel_model.getElementsByClassName('btn_left')[0]
        const btn_right = carousel_model.getElementsByClassName('btn_right')[0]
        if(btn_left.innerText!=='' && btn_right.innerText!=='') return ''
        btn_left.innerHTML='<'
        btn_right.innerHTML='>'
        const c_images = carousel_model.querySelectorAll('.image img')
        const nr_of_images = c_images.length
        const quick_switch = carousel_model.querySelector('.quick_switch')
        const c_height = carousel_model.getAttribute('height')
        const c_width = carousel_model.getAttribute('width')
        if(c_height){
            carousel_model.style.height=c_height
        }
        if(c_width){
            carousel_model.style.width=c_width
        }
        c_images.forEach((img,index)=>{
            const button = document.createElement('button');
            button.addEventListener('click', event => {
                handle_switch(event, index);
            });
            quick_switch.appendChild(button);
            button.style.setProperty('--button-background', `url(${img.getAttribute('src')})`);
        })
        function restart_progress_bar() {
            const progress_bar = carousel_model.querySelector('.progress_bar');
            progress_bar.style.animation = 'none';
            void progress_bar.offsetWidth; 
            progress_bar.style.animation = 'progress_bar 5s infinite linear'; 
        }
        let intervalId;
        function startInterval() {
            restart_progress_bar()
            intervalId = setInterval(() => {
                show_image += 1;
                handle_carousel();
            }, 5000);
        }
        startInterval()
        btn_left.onclick = ()=>{
            clearInterval(intervalId); 
            startInterval();
            show_image-=1
            handle_carousel()
        }
        btn_right.onclick = ()=>{
            clearInterval(intervalId);
            startInterval();
            show_image+=1
            handle_carousel()
        }
        let check_switch_buttons  = (image_index=0) =>{
            quick_switch.querySelectorAll('button').forEach((button,index)=>{
                if(index===image_index) button.classList.add('active')
                else button.classList.remove('active')
            })
        }
        check_switch_buttons()
        let handle_carousel = ()=>{
            if(show_image>nr_of_images-1)show_image=0
            if(show_image<0)show_image=nr_of_images-1
            check_switch_buttons(show_image)
            const all_images = carousel_model.querySelectorAll('.image img')
            const active_images = carousel_model.querySelector(`.image img:nth-child(${show_image+1})`)
            all_images.forEach(img=>{
                img.classList.remove('active')
            })
            active_images.classList.add('active')
        }
        let handle_switch = (event,image_index) =>{
            clearInterval(intervalId);
            startInterval();
            if(image_index!==show_image){
                show_image=image_index
                handle_carousel()
                check_switch_buttons(image_index)
            }
        }
    })
}

export default carousel_initialization;