import React from 'react'

function Footer() {
  return (
    <footer className='text-center bg_theme'>
      <p className='f_name'>EIKOM GmbH &copy;</p>
      <p className='address'>Glienicker Str. 520 / 12526 Berlin</p>
    </footer>
  )
}

export default Footer